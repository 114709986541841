<template>
  <v-dialog
    v-model="showForm"
    max-width="800px"
  >
    <v-card v-if="editedItem">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <action-buttons
        :id="`${editedItem.ID}${editedItem.tmpNewItemID}`"
        :onDeleteItem="onDelete"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import ActionButtons from '@/components/ActionButtons'
  export default {
    name: 'AreaForm',
    components: {
      ActionButtons,
    },
    props: {
      data: {
        type: Object,
        default: null
      },
      showForm: {
        type: Boolean,
        default: false
      },
      onChangeArea: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      },
    },
    data: () => ({
      formTitle: '',
      editedItem: null,
    }),
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      data (v) {
        this.editedItem = v
      },
    },
    methods: {
      handleSave() {
        this.onChangeArea(this.editedItem)
      },
      handleClose () {
        this.onClose()
      },
    },
  }
</script>
