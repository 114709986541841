<template>
  <div v-if="showForm">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-counter</v-icon>
      <v-toolbar-title>
        {{ $t('common.workspace', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('sysUser.name', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.TitleNumber"
            :label="$t('workspace.number', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.TitleArea"
            :label="$t('workspace.area', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <color-picker-primary-secondary
            style="width: 100px;"
            :primaryColor="editedItem.PrimaryColor"
            :secondaryColor="editedItem.SecondaryColor"
            :onChangeColor="handleChangeColors"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-switch
            v-model="editedItem.External"
            :label="$t('workspace.external', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Min"
            :label="$t('workspace.min', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Max"
            :label="$t('workspace.max', locale)"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <select-sys-users
            area="queue"
            :workspaceID="editedItem.ID"
            :onChangeUser="handleChangeUser"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <areas
            style="background-color: rgba(0, 0, 0, 0.06);" 
            v-if="editedID"
            :workspaceID="editedID"
            :onChangeAreas="handleChangeAreas"
          />
        </v-col>
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="onClose"
      :locale="locale"
    />
  </div>
</template>
<script>
  const defaultItem = {
    Name: '',
    Users: [],
    TitleNumber: 'N�mero',
    TitleArea: 'Mostrador',
    External: false,
    PrimaryColor: null,
    SecondaryColor: null,
    Areas: [],
    Min: 1,
    Max: 99,
  }
  
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import SelectSysUsers from '@/components/SelectSysUsers'
  import ActionButtons from '@/components/ActionButtons'
  import ColorPickerPrimarySecondary from '@/components/ColorPickerPrimarySecondary'
  import Areas from './areas/Index'
  export default{
    name: 'WorkspaceForm',
    components: {
      ActionButtons,
      SelectSysUsers,
      ColorPickerPrimarySecondary,
      Areas,
    },
    props: {
      editedID: {
        type: String,
        default: null
      },
      showForm: {
        type: Boolean,
        default: false
      },
      onDelete: {
        type: Function,
        required: true
      },
      onSave: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      },
    },
    data () {
      return {
        loading: false,
        editedItem: null
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      showForm (v) {
        if (v) {
          if(this.editedID) this.handleGetData()
          else this.editedItem = defaultItem
        }
      },
      editedID (v) {
        if (this.showForm) {
          if (v) this.handleGetData()
          else this.editedItem = defaultItem

        }
      },
    },
    methods: {
      handleGetData () {
        this.loading = true
        api.getItem ('queue', 'private/workspaces/', this.editedID)
        .then(response => {   
          this.editedItem = response
          this.loading = false
        })
 
      },
      handleChangeColors (primaryColor, secondaryColor) {
        this.editedItem.PrimaryColor = primaryColor ? primaryColor : null
        this.editedItem.SecondaryColor = secondaryColor ? secondaryColor : null
      },
      handleChangeUser (v) {
        this.editedItem.Users = v
      },
      handleChangeAreas (v) {
        this.editedItem.Areas = v
      },
      handleSave () {
        this.onSave(this.editedItem)
      },
      handleDelete () {
        this.onDelete(this.editedItem.ID)
      }
    }
  }
</script>
