<template>
  <div v-if="items">
    <draggable
      v-model="items"
      :draggable="'.itemDraggable'"
      style="width:100%;"
      v-bind="dragOptions"
      @start="onSortStart"
      @end="onSortItems(items)"
    >
    <div style="cursor: pointer;" 
      v-for="item in items" 
      :key="item.ID + item.Name" 
      v-bind:class="'itemDraggable'"
    >
        <v-toolbar
          color="primary"
          elevation="1"
          :style="{ 'padding-left' : `${item.Level * 20}px`}"
        >
          <!-- <v-app-bar-nav-icon>mdi-lead-pencil</v-app-bar-nav-icon> -->
          <v-icon small style="margin-right: 10px;">mdi-cursor-move</v-icon>
          <v-icon small style="margin-right: 10px;" v-if="item.Type">{{ getTypeIcon(item.Type) }}</v-icon>
          <v-toolbar-title>
            {{ item.Name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click.stop.prevent="onEdit(item)"
          >
            <v-icon x-small>mdi-pencil</v-icon>
          </v-btn>

          <delete-item
            v-if="item"
            :id="`${item.ID}${item.tmpNewItemID}`"
            :onDeleteItem="onDelete"
            icon="mdi-delete"
            :locale="locale"
          />
        </v-toolbar>
      </div>
    </draggable>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import draggable from 'vuedraggable'
  import DeleteItem from '@/components/DeleteItem'
  import constants from '@/constants'
  export default {
    name: 'draggable-table',
    components: {
      draggable,
      DeleteItem,
    },
    props: {
      data: {
        type: Array,
        required: true
      },
      onCancelEdit: {
        type: Function,
        required: true
      },
      onSortItems: {
        type: Function,
        required: false,
        default: null
      },
      onSortStart: {
        type: Function,
        required: false,
        default: null
      },
      onEdit: {
        type: Function,
        required: false,
        default: null
      },
      onDelete: {
        type: Function,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: false,
        default: null
      },
    },
    data () {
      return {
        items: null,
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      dragOptionsDisabled() {
        return {
          animation: 200,
          group: "description",
          disabled: true,
          ghostClass: "ghost"
        };
      },
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    watch:{
      data (v) {
        this.items = v
      },
      
    },
    mounted () {
      this.items = this.data
    },
    methods: {
      getTypeIcon(v) {
        const aux = constants.dviewcontentTypes.filter(x => x.value === v) 
        return aux.length > 0 ? aux[0].icon : ''
      }
    }
  }
</script>
